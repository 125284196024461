import { resumeQuery } from "./queries/resumeQuery";
import { Resume } from "@/types/ResumeTypes";
import {
  transformEducation,
  transformCertifications,
  transformVolunteerWork,
  transformWorkExperience,
} from "@/utils/dataTransformers";
import { http } from "./http";

export default {
  getResume(): Promise<Resume> {
    return http(resumeQuery).then((response) => {
      const {
        allCertification,
        allEducation,
        allExperience,
        allVolunteerWork,
        allBiography,
      } = response.data;

      return {
        biography: allBiography[0].biographyRaw,
        certifications: transformCertifications(allCertification),
        education: transformEducation(allEducation),
        experience: transformWorkExperience(allExperience),
        volunteerWork: transformVolunteerWork(allVolunteerWork),
      };
    });
  },
  downloadResume(): Promise<Response> {
    return fetch(`${process.env.VUE_APP_BACKEND}/sh/portfolio/download/cv`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      /*body: JSON.stringify({
          filename: "Steinar Hovland - CV.pdf",
          code: "cvsh",
        }),*/
    });
  },
};
