<template>
  <div class="resume-section">
    <h3 class="resume-section-title">{{ title }}</h3>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  components: {},
});
</script>

<style lang="scss" scoped>
.resume-section {
  display: flex;
  align-items: flex-start;
  break-inside: avoid;
  flex-direction: row;

  @media screen {
    padding: 3rem 2rem;
    border-top: var(--border-thick) solid var(--shx-blue-4);
  }

  @media screen and (max-width: 87rem) {
    padding: 3rem 1rem;
    flex-direction: column;
  }

  @media print {
    margin-bottom: 1cm;
  }
}

.resume-section-title {
  margin-bottom: 2rem;
  position: sticky;
  padding: 1rem 0;
  top: 0;
  background: linear-gradient(
    180deg,
    rgba(17, 23, 33, 1) 0%,
    rgba(17, 23, 33, 1) 70%,
    rgba(17, 23, 33, 0) 100%
  );
  width: 100%;

  @media (min-width: 60rem) {
    top: 1rem;
    margin-right: 7rem;
    width: 37%;
  }

  @media print {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    width: 30%;
    top: 0;
  }
}
</style>
