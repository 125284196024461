<template>
  <li class="resume-item">
    <div class="resume-item-dates">
      <time :datetime="item.from" class="resume-item-from-date">{{
        fromDate
      }}</time>
      <span class="resume-item-date-separator" v-if="toDate"> - </span>
      <time :datetime="item.to" class="resume-item-to-date">{{ toDate }}</time>
    </div>
    <div class="resume-item-content">
      <span class="resume-item-title">{{ item.title }}</span>
      <a
        v-if="item.entity.website"
        class="resume-item-payload-link"
        :href="item.entity.website"
        rel="nofollow noopener noreferrer"
      >
        {{ item.entity.name }}
      </a>
      <span v-if="!item.entity.website" class="resume-item-payload">
        {{ item.entity.name }}
      </span>
    </div>
  </li>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const toDate = computed(() => {
      const date = props.item.to;
      if (date === null) {
        return "D.D";
      } else if (!date) {
        return undefined;
      } else {
        return formatDate(date);
      }
    });

    const formatDate = (date: string) => {
      return date.split("-").reverse().join(".");
    };

    return {
      toDate,
      fromDate: formatDate(props.item.from),
    };
  },
});
</script>

<style lang="scss" scoped>
.resume-item {
  display: flex;
  padding: 1rem 0;
  @media print {
    padding: 0.3rem 0;
  }

  @media screen and (max-width: 64rem) {
    flex-direction: column;
  }

  &:not(&:first-child) {
    @media screen {
      border-top: 0.3rem solid var(--shx-blue-4);
    }
  }
}

.resume-item-title {
  display: block;
  font-weight: 700;
  color: var(--shx-white);

  @media screen and (max-width: 64rem) {
    font-size: 1.8rem;
  }

  @media print {
    font-size: 1.5rem;
  }
}

.resume-item-payload-link,
.resume-item-payload {
  @media print {
    font-size: 1.5rem;
  }
}

.resume-item-content {
  flex-basis: 70%;
}

.resume-item-dates {
  width: 20ch;
  white-space: nowrap;
  margin-right: 2.5rem;
  @media print {
    font-size: 1.5rem;
  }
}
</style>
