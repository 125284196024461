export const resumeQuery = `
{
  allCertification(
    sort: [{ certifiedDate: DESC }]
    where: { _: { is_draft: false } }
  ) {
    _id
    title
    courseUrl
    certifiedDate
    certificationAuthority {
      name
      website
    }
  }

  allEducation(
    sort: [{ startDate: DESC }]
    where: { _: { is_draft: false } }
  ) {
    _id
    title
    startDate
    endDate
    institution {
      name
      website
    }
  }

  allExperience(
    sort: [{ startDate: DESC }]
    where: { _: { is_draft: false } }
  ) {
    _id
    title
    startDate
    endDate
    company {
      name
      website
    }
  }

  allVolunteerWork(
    sort: [{ startDate: DESC }]
    where: { _: { is_draft: false } }
  ) {
    _id
    title
    startDate
    endDate
    organization {
      name
      website
    }
  }

  allTechStack {
    _id
    name
    logo {
      asset {
        url
      }
    }
    description
    url
  }

  allBiography {
    biographyRaw
  }
}
`;
