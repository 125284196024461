<template>
  <section class="resume">
    <h2 class="resume-title">CV</h2>
    <slot />
    <ResumeSection title="Personalia" class="personalia">
      <dl class="definition-list">
        <div class="definition-list-item">
          <dt>Navn</dt>
          <dd>Steinar Hovland</dd>
        </div>
        <div class="definition-list-item">
          <dt>Fødselsdato:</dt>
          <dd>25.07.88</dd>
        </div>
        <div class="definition-list-item">
          <dt>Telefon:</dt>
          <dd class="enohp"></dd>
        </div>
        <div class="definition-list-item">
          <dt>Email:</dt>
          <dd class="liame"></dd>
        </div>
      </dl>
    </ResumeSection>
    <ResumeSection title="Akkurat nå jobber jeg mest med">
      <ul class="technology">
        <li class="resume-pill">Typescript</li>
        <li class="resume-pill">Vue.js</li>
        <li class="resume-pill">CSS</li>
        <li class="resume-pill">Design system</li>
        <li class="resume-pill">UX</li>
        <li class="resume-pill">Jest</li>
        <li class="resume-pill">Amazon web services</li>
        <li class="resume-pill">Netlify</li>
        <li class="resume-pill">Node.js</li>
        <li class="resume-pill">Serverless</li>
        <li class="resume-pill">Bitbucket pipelines</li>
        <li class="resume-pill">Sanity.io</li>
        <li class="resume-pill">Github</li>
        <li class="resume-pill">Google tag manager</li>
        <li class="resume-pill">Google Analytics</li>
        <li class="resume-pill">Sikkerhet / GDPR</li>
        <li class="resume-pill resume-pill--transparent">+++</li>
      </ul>
    </ResumeSection>
    <ResumeSectionList title="Erfaring" :items="content.experience" />
    <ResumeSectionList title="Utdanning" :items="content.education" />
    <ResumeSectionList
      title="Frivillig arbeid"
      :items="content.volunteerWork"
    />
    <ResumeSectionList
      title="Kurs og sertifiseringer"
      :items="content.certifications"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ResumeSection from "./ResumeSection.vue";
import ResumeSectionList from "./ResumeSectionList.vue";
import { Resume } from "@/types/ResumeTypes";

export default defineComponent({
  props: {
    content: {
      type: Object as PropType<Resume>,
      required: true,
    },
  },
  components: {
    ResumeSection,
    ResumeSectionList,
  },
});
</script>

<style lang="scss" scoped>
.personalia {
  @media screen {
    display: none;
  }
}

.definition-list {
  margin: 0;
  padding: 0;
  width: 100%;
}

.definition-list-item {
  display: flex;
}

dt {
  font-weight: 700;
}

dd {
  margin: 0 1rem;
}

.resume {
  max-width: 100rem;
  width: 100%;
  margin: 0 auto;
}

.technology {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  width: 100%;
  @media (min-width: 87rem) {
    margin: 0 -5rem;
  }
}

.resume-pill {
  padding: 0.5rem 1.5rem 0.4rem 1.5rem;
  margin: 0.6rem 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5rem;
  font-weight: 500;

  &--transparent {
    background: transparent;
    padding: 0.5rem 0 0.4rem 0;
    @include hide-print;
  }

  @media print {
    border: 0.1rem solid #ccc;
    font-size: 1.5rem;
  }
}

.resume-title {
  @media screen {
    margin-bottom: 3rem;
    text-align: center;
  }
}

.liame::after {
  content: "junkmail@example.com";
  @media print {
    content: "cv@steinarhovland.no";
  }
}

.enohp::after {
  content: "+47 12 34 56 78";
  @media print {
    content: "+47 48 02 55 73";
  }
}
</style>
