
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const toDate = computed(() => {
      const date = props.item.to;
      if (date === null) {
        return "D.D";
      } else if (!date) {
        return undefined;
      } else {
        return formatDate(date);
      }
    });

    const formatDate = (date: string) => {
      return date.split("-").reverse().join(".");
    };

    return {
      toDate,
      fromDate: formatDate(props.item.from),
    };
  },
});
