<template>
  <ResumeSection :title="title">
    <ol class="resume-list">
      <ResumeItem :item="item" v-for="item in items" :key="item.id" />
    </ol>
  </ResumeSection>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ResumeItem from "./ResumeItem.vue";
import ResumeSection from "./ResumeSection.vue";
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ResumeItem,
    ResumeSection,
  },
});
</script>

<style lang="scss" scoped>
.resume-list {
  width: 100%;
  list-style: none;
  padding: 0;
}
</style>
