<template>
  <Spinner v-if="state.isLoading" />
  <Error
    v-if="state.errorMessage && !state.isLoading"
    :title="state.errorMessage.title"
    :description="state.errorMessage.description"
  />
  <Resume
    :content="state.resume"
    v-if="!state.errorMessage && !state.isLoading"
  />
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import ResumeService from "@/services/resume";
import { Resume as ResumeType } from "@/types/ResumeTypes";
import Error from "@/components/Error/Error.vue";
import Resume from "@/components/Resume/Resume.vue";

interface State {
  resume: ResumeType | null;
  isLoading: boolean;
  errorMessage: {
    title: string;
    description: string;
  } | null;
}

export default defineComponent({
  name: "page-cv",
  setup() {
    const state: State = reactive({
      resume: null,
      isLoading: true,
      errorMessage: null,
    });

    ResumeService.getResume()
      .then((resume) => {
        state.resume = resume;
      })
      .catch(() => {
        console.error("Loading resume failed");
        state.errorMessage = {
          title: "Oj da, dette skulle ikke skje",
          description: "Feilen er blitt rapportert, prøv igjen snart",
        };
      })
      .finally(() => {
        state.isLoading = false;
      });

    return {
      state,
    };
  },
  components: {
    Resume,
    Error,
  },
});
</script>

<style lang="scss" scoped>
.biography {
  @include hide-print;
  max-width: 70rem;
  @media screen {
    margin: 0 auto 10rem auto;
  }
}

::v-deep(.page-title) {
  @include hide-print;
}
</style>
