
import { defineComponent, PropType } from "vue";
import ResumeSection from "./ResumeSection.vue";
import ResumeSectionList from "./ResumeSectionList.vue";
import { Resume } from "@/types/ResumeTypes";

export default defineComponent({
  props: {
    content: {
      type: Object as PropType<Resume>,
      required: true,
    },
  },
  components: {
    ResumeSection,
    ResumeSectionList,
  },
});
