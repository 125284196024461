
import { defineComponent } from "vue";
import ResumeItem from "./ResumeItem.vue";
import ResumeSection from "./ResumeSection.vue";
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ResumeItem,
    ResumeSection,
  },
});
